<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :btnFlag="btnFlag"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
    >

    </app-list>
  </div>
</template>
  
  <script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/goodsBasicSetting",
      searchVal: "",
      bntLoading: false,
      btnFlag: { addShow: true, delShow: false },
      searchQuery: { siteName: "", siteCode: "" },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: "商品名称",
          prop: "name",
          align: "center",
        },

        {
          label: "主图",
          prop: "image",
          align: "center",
          type: "html",
          formatter: function (row) {
            if (row.image.indexOf(",") !== -1) {
              return (
                "<img src=api/" +
                row.image.split(",")[0] +
                " class='tablePic'/>"
              );
            } else {
              return "<img src=api/" + row.image + " class='tablePic'/>";
            }
          },
        },

        {
          label: "品牌",
          prop: "brand",
          align: "center",
        },
        {
          label: "分类",
          prop: "classificationGoodsName",
          align: "center",
        },

        {
          label: "是否上架",
          prop: "isPutaway",
          align: "center",
          type: "html",
          formatter: function (row) {
            const map = [
              { color: "#409EFF", text: "在售" },
              { color: "#666", text: "下架" },
            ];
            return (
              "<span style='color:" +
              map[row.isPutaway].color +
              "'>" +
              map[row.isPutaway].text +
              "</span>"
            );
          },
        },
        {
          label: "销售价",
          prop: "salesPrice",
          align: "center",
        },
        {
          label: "库存",
          prop: "inventory",
          align: "center",
        },
        {
          label: "storeId",
          prop: "storeId",
          align: "center",
        },
        {
          label: "操作",
          prop: "qrcode",
          align: "center",
          width: 250,
          type: "operationLink",
          formatter: function (row) {
            const buttonList = [
              {
                text: "编辑",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handlerType: "update",
                handler: function () {},
              },
              {
                text: "删除",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-delete",
                type: "danger",
                handlerType: "remove",
                handler: function () {},
              },
            ];
            return buttonList;
          },
        },
      ],
      formProps: [
        {
          label: "图片名称",
          type: "input",
          prop: "name",
        },
        {
          label: "链接",
          type: "input",
          prop: "link",
        },
        {
          label: "类型",
          type: "select",
          prop: "type",
          selectData: [
            {
              label: "幻灯片",
              value: 0,
            },
            {
              label: "广告图",
              value: 1,
            },
            {
              label: "导航图",
              value: 2,
            },
          ],
        },

        {
          label: "图片",
          type: "upload",
          prop: "address",
          limit: 1,
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
  